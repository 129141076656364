import { Component, Inject, OnInit } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { EquipmentLog } from '../../../interfaces/equipment/equipment-log';
import { EquipmentLogService } from '../../../services/equipment/equipment-log.service';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';

import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { slideUpDown } from '../../../animations/animations';
import { CommonModule } from '@angular/common';
import { EquipmentLogRowComponent } from "../equipment-log-row/equipment-log-row.component";
import { FormsModule } from '@angular/forms';
import { PlaceEquipmentComponent } from '../place-equipment/place-equipment.component';
import { Equipment } from '../../../interfaces/equipment/equipment';

@Component({
  selector: 'app-equipment-log',
  standalone: true,
  imports: [CommonModule, FormsModule, MatDialogModule, EquipmentLogRowComponent],
  templateUrl: './equipment-log.component.html',
  styleUrl: './equipment-log.component.scss',
  animations: [slideUpDown]
})
export class EquipmentLogComponent implements OnInit {
  case!: DamageCase;
  logs: EquipmentLog[] = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DamageCase,
    public dialogRef: MatDialogRef<DamageCase>,
    private dialog: MatDialog,
    private eqlogService: EquipmentLogService,
    private equipmentService: EquipmentService,
    public authService: AuthenticationService) {
      this.case = this.data;
  }

  ngOnInit(): void {
    this.equipmentService.getLogForCase(this.case.id)
      .subscribe((response: any) => {
        if (response.success) {
          this.logs = response.result;
        }
      });
  }

  cancelAdd(item: EquipmentLog): void {
      const index: number = this.logs.indexOf(item);
      this.logs.splice(index, 1);
  }

  saveMaterials() {
    this.eqlogService.saveMaterials(this.case.id, this.case.equipmentLogMaterials)
      .subscribe(() => {
        
      });
  }

  trackByLog(index: number, log: any): any {
    return log.id || index; // Replace 'id' with the unique identifier property of your log objects
}

  placeEquipment() {
    const dialogRefPlaceEquipment = this.dialog.open(PlaceEquipmentComponent, {
      width: '60%',
      disableClose: true,
      data: this.case, 
    });

    dialogRefPlaceEquipment.afterClosed()
      .subscribe(result => {
        if (result) {
          const e: Equipment = <Equipment>result;

          const newItem: EquipmentLog = {
            damageCaseId: this.case.id,
            placedEmployeeId: this.authService.userId,
            isActive: true,
            settingup: true,
            placedDate: new Date(),
            mode: "add",
            equipmentId: e.id,
            equipment: e,
            placedCounter: 0
          };      

          this.logs = [...this.logs, newItem];
        }
      });
  }
}
