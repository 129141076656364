import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { FileItem, FileUploader, FileUploadModule, ParsedResponseHeaders } from 'ng2-file-upload';
import { ProgressbarComponent } from '../ui/progressbar/progressbar.component';
import { FileUploadedArgsComponent } from '../file-uploaded-args/file-uploaded-args.component';

@Component({
  selector: 'file-upload',
  standalone: true,
  imports: [CommonModule, FormsModule, FileUploadModule, ProgressbarComponent ],
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.scss'
})
export class UploadComponent implements OnInit, OnChanges {
    @Input() uploadUrl!: string;
    @Input() placeholderText: string = "Træk filer hertil";
    @Input() browseButtonText: string = "Klik for at vælge";
    @Input() autoUpload: boolean = false;
    @Input() itemAlias: string = "logo";

    @Output() fileUploaded: EventEmitter<FileUploadedArgsComponent>;

    public uploader!: FileUploader;
    hasBaseDropZoneOver: boolean = false;

    get hasFiles(): boolean { return this.uploader.queue.length > 0; }

    constructor(public authService: AuthenticationService) {
        this.fileUploaded = new EventEmitter<FileUploadedArgsComponent>();
        
        this.uploader = new FileUploader({
            authToken: "Bearer " + this.authService.token,
            isHTML5: true,
            url: this.uploadUrl,
            itemAlias: this.itemAlias,            
        });
    }

    ngOnInit() {
        this.uploader.onSuccessItem = this.successItem;
        this.uploader.onErrorItem = this.errorItem;
        this.uploader.onCompleteAll = this.completeAll;        
        this.uploader.options.autoUpload = this.autoUpload;
    }

    //Arrow function instead of normal function, otherwise this refers to the wrong object
    protected successItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
        this.fileUploaded.emit(new FileUploadedArgsComponent(item, response, status));
        return { item, response, status, headers };

    }

    protected errorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {

    }

    protected completeAll = () => {
        //Clear files when all files have uploaded
        //Only clear queue if all items succeeded
        if (this.uploader.queue.every(x => x.isSuccess)) {
            this.uploader.clearQueue();
        }
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    upload(): void {
        this.uploader.uploadAll();
    }

    reset(): void {
        this.uploader.clearQueue();
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (let p in changes) {
            if (p === "uploadUrl") {
                this.uploader.options.url = this.uploadUrl;
            }
            if (p === "autoUpload") {
                this.uploader.options.autoUpload = this.autoUpload;
            }
            if (p === "itemAlias") {
                this.uploader.options.itemAlias = this.itemAlias;
            }
        }
    }

}
