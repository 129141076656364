import { Component, Input } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'case-details-measurement',
  standalone: true,
  imports: [],
  templateUrl: './case-details-measurement.component.html',
  styleUrl: './case-details-measurement.component.scss'
})
export class CaseDetailsMeasurementComponent {
  @Input() case!: DamageCase;

  constructor(
      private route: ActivatedRoute,
      private router: Router) {
  }

  createMeasurementLog(event: MouseEvent) {
      event.preventDefault();
      this.router.navigate(["measurement", this.case.id, { create: true }]);
  }

  navigateWithObject(caseObj: DamageCase) {
    this.router.navigate(['/measurement', caseObj.id], {
      state: { caseObject: caseObj }
    });
  }

}
