import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule } from '@angular/forms';
import { DateModel } from '../../../models/ui/date-model';
import { DatePickerOptions } from '../../../models/ui/date-picker-options';
import { DatePickerService } from '../../../services/ui/date-picker.service';

import moment from 'moment';
import { CommonModule } from '@angular/common';
import { datepickerOpts } from '../../../constants/date-picker.const';

@Component({
  selector: 'dr-datepicker',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss'
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() options: DatePickerOptions;
  @Input() inputEvents!: EventEmitter<{ type: string, data: string | DateModel }>;
  @Input() fieldid!: string;
  
  @Output() outputEvents: EventEmitter<{ type: string, data: string | DateModel }>;

  date: DateModel;
  opened: boolean;
  currentDate: moment.Moment;

  minDate: moment.Moment | any;
  maxDate: moment.Moment | any;

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  constructor( @Inject(ElementRef) public el: ElementRef, private datePickerService: DatePickerService) {
      moment.locale("da");
      this.opened = false;
      this.currentDate = moment();
      this.options = datepickerOpts;
      this.date = new DateModel();

      //this.fieldid = "dp_" + _.uniqueId();

      this.outputEvents = new EventEmitter<{ type: string, data: string | DateModel }>();

      if (!this.inputEvents) {
          return;
      }

      this.inputEvents.subscribe((event: { type: string, data: string | DateModel }) => {
          if (event.type === 'setDate') {
              this.value = event.data as DateModel;
          } else if (event.type === 'default') {
              if (event.data === 'open') {
                  this.open();
              } else if (event.data === 'close') {
                  this.close();
              }
          }
      });
  }

  get value(): DateModel {
      return this.date;
  }

  set value(date: DateModel) {
      if (!date) {
          this._clear();
          return;
      }

      if (this.date && this.date.momentObj) {
          //Copy over the time
          date.momentObj?.hour(this.date.momentObj.hour());
          date.momentObj?.minute(this.date.momentObj.minute());
          date.momentObj?.second(this.date.momentObj.second());
      }

      this.date = date;

      this.onChangeCallback(date.momentObj ? date.momentObj.toDate() : null);
  }

  ngOnInit() {
      this.options = new DatePickerOptions(this.options);
      moment.locale(this.options.locale);

      if (this.options.initialDate instanceof Date) {
          this.currentDate = moment(this.options.initialDate);
      }

      if (this.options.minDate instanceof Date) {
          this.minDate = moment(this.options.minDate);
      } else {
          this.minDate = null;
      }

      if (this.options.maxDate instanceof Date) {
          this.maxDate = moment(this.options.maxDate);
      } else {
          this.maxDate = null;
      }

      this.outputEvents.emit({ type: 'default', data: 'init' });

      if (typeof window !== 'undefined') {
          let body = document.querySelector('body');
          body!.addEventListener('click', e => {
              if (!this.opened || !e.target) { return; };
              if (this.el.nativeElement !== e.target && !this.el.nativeElement.contains((<any>e.target))) {
                  this.close();
              }
          }, false);
      }

      if (this.inputEvents) {
          this.inputEvents.subscribe((e: any) => {
              if (e.type === 'action') {
                  if (e.data === 'toggle') {
                      this.toggle();
                  }
                  if (e.data === 'close') {
                      this.close();
                  }
                  if (e.data === 'open') {
                      this.open();
                  }
              }

              if (e.type === 'setDate') {
                  if (!(e.data instanceof Date)) {
                      throw new Error(`Input data must be an instance of Date!`);
                  }
                  let date: moment.Moment = moment(e.data);
                  if (!date) {
                      throw new Error(`Invalid date: ${e.data}`);
                  }
                  this.value = {
                      day: date.format('DD'),
                      month: date.format('MM'),
                      year: date.format('YYYY'),
                      formatted: date.format(this.options.format),
                      momentObj: date
                  };
              }
          });
      }
  }

  registerOnTouched(fn: any) {
      this.onTouchedCallback = fn;
  }

  writeValue(date: Date) {
      if (!date) {
          this._clear();
          return;
      }
      this.date = this.createDateModel(date);
  }

  registerOnChange(fn: any) {
      this.onChangeCallback = fn;
  }

  open() {
      this.opened = true;
      this.outputEvents.emit({ type: 'default', data: 'opened' });
      this.datePickerService.showPicker(this.el, this.value, this.options).then(x => {
          this.value = x;
      });
  }

  close() {
      this.opened = false;
      this.datePickerService.hidePicker();
      this.outputEvents.emit({ type: 'default', data: 'closed' });
  }


  toggle() {
      if (this.opened) {
          this.close();
      }
      else {
          this.open();
      }
  }

  private _clear(): void {
  }

  clear(event: MouseEvent): void {
      if (event) {
          event.preventDefault();
      }

      this.date = new DateModel();
      this.onChangeCallback(this.date.momentObj ? this.date.momentObj.toDate() : null);
  }

  private createDateModel(date: Date): DateModel {
      let dm = new DateModel();
      let m: moment.Moment = moment(date);

      dm.day = m.format('DD');
      dm.month = m.format('MM');
      dm.year = m.format('YYYY');
      dm.formatted = m.format(this.options.format);
      dm.momentObj = m;

      return dm;

  }


  private isDateModel(value: any): value is DateModel {
      return value && value.momentObj !== undefined;
  }
}
