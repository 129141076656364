import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from '../../../models/address';
import { Customer } from '../../../models/customer';
import { DamageCaseService } from '../../../services/damage-case.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { UserService } from '../../../services/user.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { damageTypes } from '../../../types/mode.type';

@Component({
    selector: 'add-new-case',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './add-new-case-component.component.html',
    styleUrl: './add-new-case-component.component.scss',
})
export class AddNewCaseComponent {
  @Output() DamageCaseEmitted = new EventEmitter<DamageCase>();
  model!: DamageCase;
  customers!: Customer[];
  currentCustomer!: Customer;
  formtitle: string;
  submitted = false;
  damageTypes = damageTypes;

  onSubmit() {
      this.submitted = true;
      this.saveNewCase();
  }

  constructor(private damagecaseService: DamageCaseService,
      private route: ActivatedRoute,
      private router: Router,
      public authService: AuthenticationService,
      private userService: UserService) {

      this.formtitle = "OPRET NY SAG";
  }

  getUsers(): void {
      this.userService.getCustomers()
        .subscribe(dcresult => this.customers = dcresult);
  }

  ngOnInit(): void {
      this.getUsers();
      this.addNewCase();
      if (this.authService.userRole == "3") {
          this.userService.getCustomer(this.authService.userId)
              .subscribe(dcresult => this.addCurrentCustommerAddress(dcresult));
      }
  }

  addNewCase() {
      this.model = new DamageCase();
      this.model.damageAddress = new Address();
      this.model.workAddress = new Address();
      this.model.requestedByAddress = new Address();
      this.model.requestedByAddress.country = 'DK';
      this.model.damageAddress.country = 'DK';
      this.model.workAddress.country = 'DK';
      if (this.authService.userRole == "3") {
          this.model.customerId = this.authService.userId;
          this.model.caseNumber = "test123test";
      }
  }

  addCurrentCustommerAddress(cust: Customer) {
      if (cust) {
          this.model.damageAddress.street = cust.address.street;
          this.model.damageAddress.city = cust.address.city;
          this.model.damageAddress.zip = cust.address.zip;
          this.model.damageAddress.country = cust.address.country;
      }
  }

  saveNewCase() {
    this.damagecaseService.create(this.model)
        .subscribe((dc: any) => {
            // Emit new case to home case array         
            this.DamageCaseEmitted.emit(dc.result);
            this.router.navigate(["case", dc.result.id]);
        });
  }

  customerChange(target: EventTarget | null) {
    const inputElement: HTMLInputElement = target as HTMLInputElement;
    
    if (inputElement.value) {
      const currentCustomer: Customer | undefined = this.customers.find(customer => customer.id == inputElement.value);

      if (currentCustomer) {
          this.model.damageAddress.street = currentCustomer.address.street;
          this.model.damageAddress.city = currentCustomer.address.city;
          this.model.damageAddress.zip = currentCustomer.address.zip;
          this.model.damageAddress.country = currentCustomer.address.country;
      }
    }
  }

  onCancel(event: MouseEvent): void {
    event.preventDefault();
    this.router.navigate(["/"]);
  }
}
