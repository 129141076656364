import { ChangeDetectorRef, Component } from '@angular/core';
import { slideUpDownList } from '../../../animations/animations';
import { CommonModule } from '@angular/common';
import { Equipment } from '../../../interfaces/equipment/equipment';
import { EquipmentType } from '../../../interfaces/equipment/equipment-type';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { EventNotification } from '../../../interfaces/event-notification';
import { debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { ConfirmDialogComponent } from '../../ui/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-equipment-list',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  templateUrl: './equipment-list.component.html',
  styleUrl: './equipment-list.component.scss',
  animations: [slideUpDownList]
})
export class EquipmentListComponent {
  equipment: Equipment[] = [];
  typeid: number | null = null;
  type!: EquipmentType;
  searchText!: string;

  search = new FormControl();

  private originalItem!: Equipment;

  constructor(
      private equipmentService: EquipmentService,
      private dialog: MatDialog,
      private notificationService: NotificationService,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private route: ActivatedRoute) {

        this.notificationService.notifications
          .subscribe((n: EventNotification<Equipment, EquipmentType>) => {
            if (n.Type === "equipmentStatusChanged") {
              const item = this.equipment.find(x => x.id === n.Data.id);
              if (item) {
                Object.assign(item, n.Data); // Using Object.assign to merge new data
              }
            }
          });

      this.search.valueChanges
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          switchMap((q: string) => {
            if (this.typeid) {
              return this.equipmentService.getForType(this.typeid, q);
            } else {
              return this.equipmentService.getAll(q);
            }
          })
        )
        .subscribe((r: any) => (this.equipment = r));
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap(params => {
          if (params["typeid"]) {
            this.typeid = params["typeid"];
            return this.equipmentService.getForType(this.typeid!, '');
          } else {
            this.typeid = null;
            return this.equipmentService.getAll('');
          }
        })
      )
      .subscribe({
        next: (response: Equipment[]) => {
          this.equipment = response;
        },
        error: (err) => console.error('Error fetching equipment data', err)
      });

      this.route.params
      .pipe(
        switchMap((params: Params) => {
          if (params['typeid']) {
            this.typeid = params['typeid'];
            return this.equipmentService.getType(params['typeid']);
          } else {
            return of<EquipmentType[]>([]); // Use 'of' to return an empty observable
          }
        })
      )
      .subscribe((r: EquipmentType | EquipmentType[]) => {
        this.type =  r as EquipmentType;
      });      
  }

  create() {
      //If something is already in edit mode, do nothing
      if (this.equipment.findIndex(x => x.editing) > -1) {
          return;
      }
      this.equipment.push({
          number: "",
          name: "",
          equipmentTypeId: this.typeid!,
          editing: true,
          creating: true,
          state: "add"
      });
  }

  editItem(item: Equipment) {
      item.editing = true;
      // this.originalItem = clone(item);
      this.originalItem = { ...item };
  }

  cancelEdit(item: Equipment) {
      if (this.originalItem) {
        Object.assign(item, this.originalItem);
        item.editing = false;
        item.creating =  false;
      }
  }

  save(item: Equipment) {
    if (item.creating) {
      this.equipmentService.create(item).subscribe(e => {
          item.creating = false;
          item.editing = false;
          Object.assign(item, e);
      },
      err => {
          //this.modal.alert().message("Dette nummer er allerede i brug").open();
      });
    }
    else {
        this.equipmentService.update(item).subscribe(e => {
            item.editing = false;
        },
        err => {
            //this.modal.alert().message("Dette nummer er allerede i brug").open();
        });
    }
  }

  delete(item: Equipment) {
    const dialogRefDeleteEquipment = this.dialog.open(ConfirmDialogComponent, {
      width: "80%",
      maxWidth: "450px",
      disableClose: true,
      data: {
        title: "Sag",
        noButton: "Annuller",
        yesButton: "Slet udstyr",
        message: `Vil du slette dette udstyr?`
      }});

      dialogRefDeleteEquipment.afterClosed()
      .subscribe((dialogResult: boolean) => {
          if (dialogResult == true) {
            item.state = "remove";
            this.equipmentService.delete(item)
              .subscribe(i => {
                const index: number = this.equipment.findIndex(x => x.id == item.id);
                this.equipment.splice(index, 1);
              });
          } 
      });    
  }

  submitCount() {
    const data: EquipmentType = {
      id: this.type!.id!,
      name: this.type!.name,
      meterUnit: this.type!.meterUnit,
      totalAvailable: this.type!.totalAvailable!
    };

    this.equipmentService.updateTotalAvailableForType(data)
      .subscribe(x => {
        this.cdRef.detectChanges();
      });
  }
}
