import { Component, Input, OnInit } from '@angular/core';
import { CaseDetailSectionComponent } from '../case-detail-section/case-detail-section.component';
import { DamageCase } from '../../../models/damage-case';
import { DamageCaseService } from '../../../services/damage-case.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-case-checkin',
  standalone: true,
  imports: [],
  templateUrl: './case-checkin.component.html',
  styleUrl: './case-checkin.component.scss'
})
export class CaseCheckinComponent extends CaseDetailSectionComponent implements OnInit {
  @Input() override case!: DamageCase;
  // service!: DamageCaseService;

  constructor(authService: AuthenticationService,
      private damagecaseService: DamageCaseService,
      private userService: UserService)
  {
      super(authService);
  }


  override ngOnInit() {

  }

  caseCheckin(event: MouseEvent) {
      //Opdatere status på case herfra.
      this.damagecaseService.checkin(this.case)
      .subscribe(c => {
        Object.assign(this.case, c);
          this.onUpdated();
      });
  }
}
