import { EventEmitter, Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { Employee } from '../models/employee';
import { ApiResult } from '../models/api-result';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ServiceBase {
  private listurl = '/api/companyusers/';
  private createcustomerurl = 'api/admin/customer/create';
  private updatecustomerurl = 'api/admin/customer/update';

  customerUpdated: EventEmitter<Customer>;

  constructor(authhttp: HttpClient, alertService: AlertService) {
      super(authhttp);
      this.customerUpdated = new EventEmitter<Customer>();
  }

  getCustomers(): Observable<Customer[]> {
    return this.getJson(this.listurl + 'customers')
  }

  getEmployees(): Observable<Employee[]> {
    return this.getJson(this.listurl + 'employees');
  }

  getCustomer(customerId: string | number | boolean): Observable<Customer> {
    return this.getJson(this.listurl + 'getcustomer/' + encodeURIComponent(customerId));
  }

  create(customer: Customer): Observable<ApiResult<Customer>> {
    return this.postJson(this.createcustomerurl, customer)
  }

  update(customer: Customer): Observable<ApiResult<Customer>> {
    return this.postJson(this.updatecustomerurl, customer);
  }
}
