import { Component, OnInit } from '@angular/core';
import { EquipmentType } from '../../../interfaces/equipment/equipment-type';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import { EventNotification } from '../../../interfaces/event-notification';
import { filter, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'equipment-statistics',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './equipment-statistics.component.html',
  styleUrl: './equipment-statistics.component.scss'
})
export class EquipmentStatisticsComponent implements OnInit {
  types: EquipmentType[] = [];

  constructor(
      private equipmentService: EquipmentService,
      public authService: AuthenticationService,
      public router: Router,
      private route: ActivatedRoute,
      private notificationService: NotificationService
  ) {
    equipmentService.getTypes()
      .pipe(filter((r: EquipmentType[]) => r != null))
      .subscribe((data: EquipmentType[]) => {
        this.types = data;
      });

    notificationService.notifications
    .pipe(
        map(n => <EventNotification<EquipmentType[], any>>n)
    )
    .subscribe(n => {
        if (n.Type === "equipmentCountUpdated") {
            for (let t of this.types) {
                var item = n.Data.find(x => x.id === t.id);
                Object.assign(t, item);
            }
        }
    });
  }

  ngOnInit(): void {
      this.equipmentService.getTypes()
        .pipe(filter((r: EquipmentType[]) => r != null))
        .subscribe(t => {
            this.types = t.filter(x => x.showStatusOnHome);
        })
  }
}
