import { Component, OnInit } from '@angular/core';
import { Vehicle } from '../../../interfaces/vehicles/vehicle';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { DamageCaseService } from '../../../services/damage-case.service';
import { VehicleService } from '../../../services/vehicles/vehicle.service';
import { CaseDetailSectionComponent } from '../../case/case-detail-section/case-detail-section.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'case-vehicle-list',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './case-vehicle-list.component.html',
  styleUrl: './case-vehicle-list.component.scss'
})
export class CaseVehicleListComponent extends CaseDetailSectionComponent implements OnInit {
    
  selectedVehicle: Vehicle | null = null;
  allVehicles!: Vehicle[];

  constructor(authService: AuthenticationService,
      private damageCaseService: DamageCaseService,
      private vehicleService: VehicleService
  ) {
      super(authService);
  }

  override ngOnInit() {
    // Get Vehicles
    this.vehicleService.getVehicles()
        .subscribe((result: Vehicle[]) => {
            this.allVehicles = result;
        });
  }


  addVehicle() {
      if (this.selectedVehicle && this.case.vehicles.find(x => x.id == this.selectedVehicle?.id) == undefined) {
          this.damageCaseService.addVehicle(this.case, this.selectedVehicle)
            .subscribe(v => {
              this.case.vehicles.push(v);
              this.onUpdated();
              this.selectedVehicle = null;
            });
      }
  }

  removeVehicle(event: Event, vehicle: Vehicle) {
      event.stopPropagation();
      this.damageCaseService.removeVehicle(this.case, vehicle).subscribe(e => {
          this.case.vehicles.splice(this.case.vehicles.indexOf(vehicle), 1);
          this.onUpdated();
      });
  }

  override onCaseChanged() {
      this.selectedVehicle = null;
  }
}
