import { Routes } from '@angular/router';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CaseDetailsComponent } from './components/case/case-details/case-details.component';
import { EditReportComponent } from './components/reports/edit-report/edit-report.component';
import { CreateReportComponent } from './components/reports/create-report/create-report.component';
import { LoginComponent } from './components/login/login.component';
import { ForgottenPasswordComponent } from './components/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RegisterCustomerComponent } from './components/customers/register-customer/register-customer.component';
import { HouseholdEffectComponent } from './components/household-effect/household-effect.component';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { HomeStatisticsComponent } from './components/home-statistics/home-statistics.component';
import { EditCaseComponent } from './components/case/edit-case/edit-case.component';
import { VehiclesComponent } from './components/vehicles/vehicles/vehicles.component';
import { VehicleOverviewComponent } from './components/vehicles/vehicle-overview/vehicle-overview.component';

import { EquipmentComponent } from './components/equipment/equipment/equipment.component';
import { EquipmentDetailsComponent } from './components/equipment/equipment-details/equipment-details.component';
import { EquipmentOverviewComponent } from './components/equipment/equipment-overview/equipment-overview.component';

import { EmployeesComponent } from './components/employees/employees/employees.component';
import { AddEmployeeComponent } from './components/employees/add-employee/add-employee.component';
import { EmployeeDetailsComponent } from './components/employees/employee-details/employee-details.component';

import { CustomersComponent } from './components/customers/customers/customers.component';
import { AddCustomerComponent } from './components/customers/add-customer/add-customer.component';
import { CustomerDetailsComponent } from './components/customers/customer-details/customer-details.component';

import { SettingsComponent } from './components/settings/settings/settings.component';
import { ReportFailureComponent } from './components/equipment/report-failure/report-failure.component';
import { VehicleDetailsComponent } from './components/vehicles/vehicle-details/vehicle-details.component';
import { MeasurementComponent } from './components/measurement/measurement/measurement.component';
import { AddNewCaseComponent } from './components/case/add-new-case/add-new-case-component.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgottenpassword', component: ForgottenPasswordComponent },
  { path: 'register-customer', component: RegisterCustomerComponent },
  {
    path: 'welcome/:companyid',
    canActivate: [AuthGuard],
    component: WelcomeComponent
  },
  {
    path: 'resetpassword/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'householdeffects',
    component: HouseholdEffectComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vehicles',
    component: VehiclesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: VehicleOverviewComponent },
      { path: ':vehiclesid', component: VehicleDetailsComponent },
    ]
  },
  {
    path: 'equipment',
    component: EquipmentComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: EquipmentOverviewComponent },
      { path: ':typeid', component: EquipmentOverviewComponent },
      { path: 'details/:equipmentid', component: EquipmentDetailsComponent },
      { path: 'failures/:equipmentid', component: ReportFailureComponent },
    ]
  },
  {
    path: 'employees',
    component: EmployeesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'create', component: AddEmployeeComponent },
      { path: ':employeeid', component: EmployeeDetailsComponent },
    ]
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'create', component: AddCustomerComponent },
      { path: ':id', component: CustomerDetailsComponent },
    ]
  },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeStatisticsComponent },
      { path: 'addcase', component: AddNewCaseComponent },
      { path: 'case/:caseid', component: CaseDetailsComponent },
      { path: 'case/:caseid/edit', component: EditCaseComponent },
      { path: 'case/:caseid/report', component: EditReportComponent },
      { path: 'case/:caseid/createreport', component: CreateReportComponent },
    ]
  },
  { path: 'measurement/:caseid', component: MeasurementComponent, canActivate: [AuthGuard] },
  { path: 'measurement/:caseid/:logid', component: MeasurementComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' }
];


//export const routes = RouterModule.forRoot(appRoutes);
//export const routes: Routes = appRoutes;
// export const routes: Routes = [];
