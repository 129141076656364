import { Injectable } from '@angular/core';
import { ServiceBase } from '../base/service-base.service';
import { AlertService } from '../alert.service';
import { Vehicle } from '../../interfaces/vehicles/vehicle';
import { Observable } from 'rxjs';
import { Employee } from '../../models/employee';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VehicleService  extends ServiceBase  {
  private baseurl = '/api/vehicle/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  getVehicles(): Observable<Vehicle[]> {
      return this.getJson<Vehicle[]>(`${this.baseurl}`);
  }

  getDetails(id: number): Observable<Vehicle> {
      return this.getJson<Vehicle>(`${this.baseurl}${id}`);
  }

  create(item: Vehicle): Observable<Vehicle> {
      return this.postJson<Vehicle>(`${this.baseurl}`, item);
  }

  update(item: Vehicle): Observable<Vehicle> {
      return this.patchJson<Vehicle>(`${this.baseurl}`, item);
  }
  
  saveVehicle(vehicle: Vehicle): Observable<Vehicle> {
      return this.patchJson(`${this.baseurl}`, vehicle);
  }

  delete(item: Vehicle): Observable<Vehicle> {
      return this.deleteJson(`${this.baseurl}${item.id}`);
  }

  addEmployee(vehicleId: number, employee: Employee): Observable<Vehicle> {
      return this.putJson(`${this.baseurl}${vehicleId}/employees`, employee)
  }

  removeEmployee(vehicleId: number, employee: Employee): Observable<Vehicle> {
      return this.deleteJson(`${this.baseurl}${vehicleId}/employees/${employee.id}`)
  }
}
