import { isPlatformBrowser } from "@angular/common";
import { inject, PLATFORM_ID } from "@angular/core";

export function tokenGetter(): string | null {
    const platformId: Object = inject(PLATFORM_ID);
    
    if (isPlatformBrowser(platformId)) {
      return localStorage.getItem("access_token");
    }
  
    return null;
  }
