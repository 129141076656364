import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HouseholdEffect } from '../../models/household-effect';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { HouseholdEffectService } from '../../services/household-effect.service';
import { DatePickerComponent } from '../ui/date-picker/date-picker.component';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

import { default as _rollupMoment } from 'moment';
import moment from 'moment';
import 'moment/locale/da';;

@Component({
  selector: 'app-household-effect',
  standalone: true,
  providers: [
    provideMomentDateAdapter(),
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DatePipe, DatePickerComponent, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule],
  templateUrl: './household-effect.component.html',
  styleUrl: './household-effect.component.scss'
})
export class HouseholdEffectComponent implements OnInit {
  householdeffects!: HouseholdEffect[];
  addingnew!: boolean;
  selectedDate: any;
  datepickerControl: any = new FormControl(moment(null));
  selectedHouseholdEffect: HouseholdEffect | null = new HouseholdEffect();

  constructor(
    private householdeffectService: HouseholdEffectService,
    public authService: AuthenticationService) {
      moment.locale('da');
  }

  onDateChange(event: any) {
    this.selectedDate = moment(event.value).format('DD.MM.YYYY');  // Format date using moment
    this.selectedHouseholdEffect!.itemAcquisitionDate = new Date(moment(event.value).format('YYYY-MM-DDTHH:mm:ss'));    
  }

  getHouseholdEffects(): void {
    this.householdeffectService.list(this.authService.userId)
      .subscribe({
        next: (result: any) => {
          this.householdeffects = result.result;
        },
        error: (err) => {
          // Handle error
        }
      });
  }

  ngOnInit(): void {
      this.getHouseholdEffects();
  }

   onSelect(householdeffect: HouseholdEffect): void {
      if (this.selectedHouseholdEffect === householdeffect) {
          this.selectedHouseholdEffect = null;
      }
      else {
        this.selectedHouseholdEffect = householdeffect;

        if (this.selectedHouseholdEffect.customerId == null) {
          this.selectedHouseholdEffect.customerId = this.authService.userId;
        }

        const dateNullValue = moment('0001-01-01T00:00:00+00:00');
        const currentDateValue = moment(householdeffect.itemAcquisitionDate);

        if (dateNullValue.isSame(currentDateValue)) {
          this.datepickerControl.setValue(null);
        } else {
          this.datepickerControl.setValue(moment(householdeffect.itemAcquisitionDate));
        }
      }
  }

  delete(item: HouseholdEffect): void {
    this.householdeffectService.delete(item).subscribe({
      next: () => {
        this.householdeffects = this.householdeffects.filter(effect => effect !== item);
        this.selectedHouseholdEffect = null;
        this.selectedDate = null;
        this.datepickerControl.setValue(null);
      },
      error: (err) => {
        // Handle error if necessary
        console.error('Error:', err);
      }
    });    
  }

  addNewHouseholdEffect(): void {
      this.addingnew = true;
      this.selectedHouseholdEffect = new HouseholdEffect();
      this.selectedHouseholdEffect.customerId = this.authService.userId;
      this.selectedDate = null;
      this.datepickerControl.setValue(null);
  }

  cancelAddingNew(): void {
      this.addingnew = false;
      this.selectedHouseholdEffect = null;
      this.selectedDate = null;
      this.datepickerControl.setValue(null);
  }

  onSubmitUpdateHousehold() {
    if (this.addingnew) {
      this.householdeffectService.create(this.selectedHouseholdEffect as HouseholdEffect)
        .subscribe(r => {
          this.householdeffects.push(this.selectedHouseholdEffect as HouseholdEffect);
          this.selectedHouseholdEffect = null;
          this.addingnew = false;
        });
    } else {
      this.householdeffectService.update(this.selectedHouseholdEffect as HouseholdEffect)
        .subscribe((data: HouseholdEffect) => {
          //console.log("Retur data: ", data);
        });      
    }
  }
}
