import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CaseDetailSectionComponent } from '../case-detail-section/case-detail-section.component';
import { DamageCase } from '../../../models/damage-case';
import { DatePickerOptions } from '../../../models/ui/date-picker-options';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { DamageCaseService } from '../../../services/damage-case.service';
import { UserService } from '../../../services/user.service';
import { EventNotification } from '../../../interfaces/event-notification';
import { DamageCaseAppointment } from '../../../interfaces/damage-case-appointment';
import { NotificationService } from '../../../services/notification.service';
import { filter, map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserAvatarComponent } from "../../user-avatar/user-avatar.component";
import { convertToDate } from '../../../utils/date-helpers';
import { slideUpDownList } from '../../../animations/animations';
import { EmployeesService } from '../../../services/employees/employees.service';

@Component({
    selector: 'app-case-appointment-list',
    standalone: true,
    templateUrl: './case-appointment-list.component.html',
    styleUrl: './case-appointment-list.component.scss',
    imports: [CommonModule, FormsModule, UserAvatarComponent],
    animations: [slideUpDownList]
})
export class CaseAppointmentListComponent extends CaseDetailSectionComponent implements OnInit, OnChanges {
  @Input() override case!: DamageCase;
  newAppointmentDate!: string | null;
  newAppointmentTime!: string | null;
  newAppointmentType!: number | null;
  newAppointmentDescription!: string | null;


  dateOptions: DatePickerOptions = {
    minDate: new Date(),
    autoApply: false,
    style: 'normal',
    locale: '',
    maxDate: undefined,
    initialDate: undefined,
    firstWeekdaySunday: false,
    format: ''
  };

  // override authService: any;

  constructor(authService: AuthenticationService,
      private damagecaseService: DamageCaseService,
      private userService: UserService,
      private employeesService: EmployeesService,
      private cdRef: ChangeDetectorRef,
      private notificationService: NotificationService) {
      super(authService);

      notificationService.notifications
      .pipe(
        map(n => <EventNotification<any, DamageCase>>n),
        filter(n => n.Context && n.Context.id === this.case.id)
      )
      .subscribe(n => {
        if (n.Type === "appointmentCreated") {
          let appointment = <DamageCaseAppointment>n.Data;
          appointment.state = "add";
          this.case.appointments.push(n.Data);
    
          this.onUpdated();
        }
        if (n.Type === "appointmentUpdated") {
          const newappointment = <DamageCaseAppointment>n.Data;
          const appointment: DamageCaseAppointment | undefined = this.case.appointments.find(x => x.id === newappointment.id);
    
          if (appointment) {
            Object.assign(appointment, newappointment);
    
            this.onUpdated();
          }
        }

        if (n.Type === "appointmentDeleted") {
          const a: DamageCaseAppointment = <DamageCaseAppointment>n.Data;
    
          const appointment: DamageCaseAppointment | undefined = this.case.appointments.find(x => x.id === a.id);
          if (appointment) {
            appointment.state = "remove";
            appointment.editing = false;
    
            this.case.appointments.splice(this.case.appointments.indexOf(appointment), 1);
    
            this.onUpdated();
          }
        }
      });

      // notificationService.notifications
      //     .map(n => <EventNotification<any, DamageCase>>n)
      //     .filter(n => n.context && n.context.id === this.case.id)
      //     .subscribe(n => {
      //         if (n.type === "appointmentCreated") {
      //             var appointment = <DamageCaseAppointment>n.data;
      //             appointment.state = "add";
      //             this.case.appointments.push(n.data);

      //             this.onUpdated();
      //         }
      //         if (n.type === "appointmentUpdated") {
      //             var newappointment = <DamageCaseAppointment>n.data;
      //             var appointment: DamageCaseAppointment = this.case.appointments.find(x => x.id === newappointment.id);

      //             assign(appointment, newappointment);

      //             this.onUpdated();
      //         }
      //         if (n.type === "appointmentDeleted") {
      //             var a = <DamageCaseAppointment>n.data;

      //             var appointment: DamageCaseAppointment = this.case.appointments.find(x => x.id === a.id);
      //             if (appointment) {
      //                 appointment.state = "remove";
      //                 appointment.editing = false;

      //                 this.case.appointments.splice(this.case.appointments.indexOf(appointment), 1);

      //                 this.onUpdated();
      //             }
      //         }
      //     });
  }

  override onUpdated() {
    throw new Error('Method not implemented.');
  }

  override ngOnInit() {
  }

  override ngOnChanges(changes: SimpleChanges): void {
      for (let p in changes) {
          if (p == "case") {
              this.newAppointmentDate = null;
              this.newAppointmentDescription = null;
              this.newAppointmentType = null;
          }
      }
  }

  saveAppointment() {
      if (!!this.newAppointmentDate && !!this.newAppointmentTime) {
        const convertedDate: Date | null = convertToDate(this.newAppointmentDate as string, this.newAppointmentTime as string);

        if (convertedDate) {
          const appointment: DamageCaseAppointment = {
              appointmentDate: convertedDate,
              createdById: this.authService.userId,
              created: new Date(),
              description: this.newAppointmentDescription as string,
              damageCaseId: this.case.id,
              type: this.newAppointmentType as number,
              state: "add"
          };
          
          this.damagecaseService.addAppointment(appointment)
          .subscribe((n: any) => {
              const damageCaseAppointment: DamageCaseAppointment = n.result;
              this.case.appointments = [...(this.case.appointments || []), damageCaseAppointment];
            });
        }
      }

      this.newAppointmentDate = null;
      this.newAppointmentTime = null;
      this.newAppointmentDescription = null;
  }

  appointmentSelected(appointment: DamageCaseAppointment): void {
      if (this.case.damageStatusId != 3) {
          appointment.editing = true;
      }
  }

  updateAppointment(event: { stopPropagation: () => void; }, appointment: DamageCaseAppointment): void {
      event.stopPropagation();
      appointment.editing = false;
      this.damagecaseService.updateAppointment(appointment).subscribe(n => {
      });
  }

  deleteAppointment(event: { stopPropagation: () => void; }, appointment: DamageCaseAppointment): void {
      event.stopPropagation();
      appointment.state = "remove";
      appointment.editing = false;

      this.damagecaseService.deleteAppointment(appointment)
        .subscribe(n => {
          const index: number = this.case.appointments.findIndex(appointment => appointment.id === appointment.id);
          if (index !== -1) {
              this.case.appointments.splice(index, 1);
              this.cdRef.detectChanges();
          }          
        });
  }

  appointmentHeld(event: { stopPropagation: () => void; }, appointment: DamageCaseAppointment): void {
      event.stopPropagation();

      this.damagecaseService.updateAppointment(appointment).subscribe(n => {
        appointment.isHeld = true;
        this.employeesService.getEmployee(this.authService.userId)
          .subscribe(r => {
            appointment.heldById = r.id;
            appointment.heldBy = r;
            this.cdRef.detectChanges();
          });
      });
  }

  updateAppointmentDateTime(
    date: string | Date | null,
    time: string | null,
    appointmentToUpdate: DamageCaseAppointment
  ) {
    const newDate = date ? new Date(date) : null;
    const [hours, minutes] = time ? time.split(":").map(Number) : [null, null];
  
    // Validate date and time inputs
    if (date && (!newDate || isNaN(newDate.getTime()))) {
      console.error("Invalid date provided:", date);
      return;
    }
    if (time && (hours === null || isNaN(hours) || isNaN(minutes as number))) {
      console.error("Invalid time format:", time);
      return;
    }
  
    // Find the appointment in the list
    const appointment = this.case.appointments.find(
      (a) => a.id === appointmentToUpdate.id
    );
  
    if (!appointment) {
      console.error("Appointment not found in the list.");
      return;
    }
  
    const existingDateTime = appointment.appointmentDate
      ? new Date(appointment.appointmentDate)
      : new Date();
  
    // Update the appointment date and time
    appointment.appointmentDate = new Date(
      date ? newDate!.getFullYear() : existingDateTime.getFullYear(),
      date ? newDate!.getMonth() : existingDateTime.getMonth(),
      date ? newDate!.getDate() : existingDateTime.getDate(),
      time !== null && hours !== null ? hours : existingDateTime.getHours(),
      time !== null && minutes !== null ? minutes : existingDateTime.getMinutes(),
      0, // Set seconds to 0
      0 // Set milliseconds to 0
    );
  }
  
}
