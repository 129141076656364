import { Component, OnInit } from '@angular/core';
import { ReportFormBaseComponent } from '../report-form-base/report-form-base.component';
import { CloudBurstReport } from '../../../models/report/cloud-burst-report';
import { CloudBurstReportService } from '../../../services/cloud-burst-report.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { filter } from 'rxjs';
import { EmployeesService } from '../../../services/employees/employees.service';
import { Employee } from '../../../models/employee';

@Component({
  selector: 'report-cloudburst',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cloud-burst-report-form.component.html',
  styleUrl: './cloud-burst-report-form.component.scss'
})
export class CloudBurstReportFormComponent extends ReportFormBaseComponent<CloudBurstReport> implements OnInit {
  constructor(
    authService: AuthenticationService, 
    private employeesService: EmployeesService,
    private cloudBurstReportService: CloudBurstReportService) {
      super(authService);
      this.formModel = new CloudBurstReport();
  }

  override ngOnInit() {
    if (this.mode == "create") {
      this.formModel.damageCaseId = this.case.id;
    }
    else if (this.mode == "edit" && this.reportId) {
      //We are in edit mode and have the data we need to go load the report from the server
      this.cloudBurstReportService.get(this.reportId)
        .pipe(filter((result: CloudBurstReport) => result != null))
        .subscribe((report: any) => {
          this.formModel = report.result;
        });
    }
  }

  override onSubmit() {
    this.employeesService.getEmployee(this.authService.userId)
      .subscribe((result: Employee) => {
        if (this.mode == "create") {
          this.formModel.createdBy = result;
          this.formModel.updatedBy = result;
          this.formModel.created = new Date();
          this.formModel.updated = new Date();

          this.cloudBurstReportService.create(this.formModel)
            .subscribe((r: CloudBurstReport) => this.created.emit(r));
        }
        else {
          this.formModel.updatedBy = result;
          this.formModel.updated = new Date();
          
          this.cloudBurstReportService.update(this.formModel)
            .subscribe((r: CloudBurstReport) => this.saved.emit(r));
        }
      })
  }

}
