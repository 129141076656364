<div class="casedetails" *ngIf="case">
  <!-- <button class="back-to-list" (click)="goToList()"><i class="fa fa-angle-left"></i> Tilbage til liste</button> -->

  <div class="casedetailpanel case-info">
    <div class="info">
      <span class="damagetype">{{case.damageTypeName}}</span>
      <span class="damagedate" *ngIf="case.damageDate">{{case.damageDate | date:'dd-MM-YYYY'}}</span>
      <span class="status">
        {{case.damageStatusName}}
        <span *ngIf="case.checkinDate">, {{ (case.checkinDate |  momentDiff:'days':currentDate:true) | abs }} dg</span>
        <span *ngIf="case.reopenDate">{{ (case.reopenDate |  momentDiff:'days':currentDate:true) | abs }} dg</span>
      </span>
      <span *ngIf="case.queueNumber">
        ({{case.queueNumber}})
      </span>
      <a class="edit" *ngIf="authService.isAdmin && case.damageStatusId < 3" [routerLink]="['/case', this.case.id, 'edit']" title="Rediger sag"><i class="fa fa-pencil-square-o"></i><span class="visuallyhidden"></span></a>
    </div>

    <div class="customer">
      <div class="customer-address">
        <h1>{{case.customer.fullName}}</h1>
        {{case.customer.address.street}},
        {{case.customer.address.zip}} {{case.customer.address.city}}
      </div>
      <div class="damage-address" *ngIf="!isAddressIdentical(case.damageAddress, case.customer.address)">
        <h2>Skadesadresse</h2>
        {{case.damageAddress.street}},
        {{case.damageAddress.zip}} {{case.damageAddress.city}}
      </div>
      <div class="requested-by" *ngIf="case.requestedByName">
        <h2>Rekvirent</h2>
        {{case.requestedByName}}
        <span *ngIf="case.requestedByAddress.street !== null">
          ,{{case.requestedByAddress.street}}, {{case.requestedByAddress.zip}} {{case.requestedByAddress.city}}
        </span>
      </div>
    </div>
    <div class="description" *ngIf="case.shortDescription">
      <h2>Kort beskrivelse</h2>
      {{case.shortDescription}}
    </div>

    <div *ngIf="case.workAddress.street">
        <b>Arbejdsadresse:</b><br />
        {{case.workAddress.street}},
        {{case.workAddress.zip}} {{case.workAddress.city}}
    </div>

    <div class="close-case" *ngIf="!authService.isCustomer">
      <div *ngIf=" (case.damageStatusId == 2 || case.damageStatusId == 4) && case.canClose">
        <button (click)="closeCase();">Luk sagen</button>
      </div>

      <div class="notice" *ngIf=" (case.damageStatusId == 2 || case.damageStatusId == 4) && !case.canClose">
        Sagen kan ikke lukkes, før der er tildelt en bil, alle maskiner er nedtaget og der er tilføjet mindst en kommentar.
      </div>

      <div *ngIf=" case.damageStatusId == 3 ">
        <button (click)="reopenCase();">Genåbn sagen</button>
      </div>
    </div>
  </div>

  <div *ngIf="!authService.isCustomer && case.damageStatusId == 1">
    <div class="casedetailpanel">
      <app-case-checkin [case]="case" (updated)="childUpdated()"></app-case-checkin>
    </div>
  </div>

  <div class="casedetailpanel" *ngIf="authService.isCustomer && case.damageStatusId == 3">
    <h2>Kundetilfredshed</h2>
    <app-customer-satisfaction [case]="case" [rating]="case.customerSatisfactionRating" [maxStars]="5"></app-customer-satisfaction>
  </div>

  <div *ngIf="authService.isAdmin">
    <div class="casedetailpanel">
      <h2>Biler</h2>
      <case-vehicle-list [case]="case" (updated)="childUpdated()"></case-vehicle-list>
    </div>
  </div>

  <div class="casedetailpanel">
    <h2>Kommentarer</h2>
     <app-case-note-list [case]="case" (updated)="childUpdated()"></app-case-note-list>
  </div>

  <div class="casedetailpanel">
    <h2>Besøg/Møde</h2>
     <app-case-appointment-list [case]="case" (updated)="childUpdated()"></app-case-appointment-list> 
  </div>

  <div class="casedetailpanel">
    <h2>Raporter og journaler</h2>
    <!--Rapporter må åbnes af alle undtagen user-->
    <div *ngIf="!authService.isCustomer" class="casedetailpanel">
      <!-- <h2>Rapporter</h2> -->
      <app-case-details-reports [case]="case"></app-case-details-reports>
    </div>

    <!--Maskinjournal må åbnes af alle undtagen user-->
    <div *ngIf="!authService.isCustomer && case.damageStatusId != 1 && case.hasReport" class="casedetailpanel">
      <button (click)="openEquipmentLog()">Åbn maskinjournal</button>
    </div>

    <!--0+ er for at tvinge typescript til at gøre det til et tal, når der laves AOT-kompilering-->
    <!--Målejournal må åbnes af af alle undtagen user-->
    <div *ngIf="!authService.isCustomer && (case.damageStatusId != 0+1 || case.damageStatusId != 0+3)" class="casedetailpanel">
      <case-details-measurement *ngIf="case" [case]="case"></case-details-measurement>
    </div>

    <div *ngIf="case.hasReport" class="casedetailpanel">
      <a (click)="downloadPdfReport(case.id)"><i class="fa fa-file-pdf-o"></i> Hent Skadeservicerapport som PDF</a>
    </div>
  </div>

  <div class="casedetailpanel" *ngIf="!authService.isCustomer && case.damageStatusId != 1 && case.hasReport">
    <untracked-equipment [case]="case"></untracked-equipment>
  </div>

  <div *ngIf="authService.isCustomer" class="casedetailpanel">
    <h2>Vælg din håndværker</h2>
    <case-builder-list [case]="case"></case-builder-list>
  </div>

  <div *ngIf="case.damageStatusId != 3" class="casedetailpanel">
    <h2>Erstatning</h2>
    <case-details-compensation *ngIf="case" [case]="case"></case-details-compensation>
  </div>

  <div *ngIf="case.damageStatusId != 3" class="casedetailpanel">
    <h2>Filer</h2>
    <case-details-attachments [case]="case"></case-details-attachments>
  </div>

  <div class="casedetailpanel">
    <h2>Kørselsvejledning</h2>
    <driving-directions *ngIf="case.company" [case]="case" [baseAddress]="case.company.baseAddress"></driving-directions>
  </div>

  <div class="casedetailpanel" *ngIf="authService.isSuperAdmin">
    <h2>Slet</h2>
    <button (click)="delete()">Slet sag</button>
  </div>
</div>
