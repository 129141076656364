import { Component, OnInit } from '@angular/core';
import { Equipment } from '../../../interfaces/equipment/equipment';
import { EquipmentLog } from '../../../interfaces/equipment/equipment-log';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { EventNotification } from '../../../interfaces/event-notification';
import { EquipmentType } from '../../../interfaces/equipment/equipment-type';
import { filter, map, switchMap } from 'rxjs/operators';
import { EquipmentRepairHistory } from '../../../interfaces/equipment/equipment-repair-history';
import { CommonModule, DecimalPipe } from '@angular/common';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { MomentPipe } from "../../../pipes/moment.pipe";
import { UnitPipe } from "../../../pipes/unit.pipe";
import { MatDialog } from '@angular/material/dialog';
import { ReportFailureComponent } from '../report-failure/report-failure.component';

@Component({
    selector: 'app-equipment-details',
    standalone: true,
    templateUrl: './equipment-details.component.html',
    styleUrl: './equipment-details.component.scss',
    imports: [CommonModule, UserAvatarComponent, MomentPipe, RouterModule, DecimalPipe]
})
export class EquipmentDetailsComponent  implements OnInit {
  equipmentId!: string | null;
  equipment!: Equipment;
  activeLog!: EquipmentLog;

  constructor(
      private equipmentService: EquipmentService,
      private notificationService: NotificationService,
      public dialog: MatDialog,
      private router: Router,
      private route: ActivatedRoute
  ) {
    this.route.params
      .pipe(
        switchMap((params: Params) => this.equipmentService.getDetails(params["equipmentid"])),
        filter(result => result != null)
      )
      .subscribe((r: Equipment | null) => {
        if (r != null) {
          this.equipment = r;
          
          const activeLog: EquipmentLog | undefined = this.equipment.logs?.find(x => x.isActive);
          if (activeLog) {
            this.activeLog = activeLog;
          }
        }
      });

      // this.route.params
      //     .switchMap((params: Params) => this.equipmentService.getDetails(params["equipmentid"]))
      //     .subscribe(r => {
      //         this.equipment = r;
      //         var activeLog = this.equipment.logs.find(x => x.isActive);
      //         if (activeLog) {
      //             this.activeLog = activeLog;
      //         }
      //     });

      notificationService.notifications.pipe(
        map(n => <EventNotification<Equipment, EquipmentType>>n),
        filter(n => n.Data && this.equipment && n.Data.id === this.equipment.id && n.Type === "equipmentStatusChanged"),
        switchMap(n => this.equipmentService.getDetails(n.Data.id!))
      ).subscribe(n => {
        Object.assign(this.equipment, n);
        var activeLog = this.equipment.logs?.find(x => x.isActive);
        if (activeLog) {
          this.activeLog = activeLog;
        }
      });

      // notificationService.notifications
      //     .map(n => <EventNotification<Equipment, EquipmentType>>n)
      //     .filter(n => n.data && this.equipment && n.data.id === this.equipment.id && n.type === "equipmentStatusChanged")
      //     .switchMap(n => this.equipmentService.getDetails(n.data.id))
      //     .subscribe(n => {
      //         Object.assign(this.equipment, n);
      //         var activeLog = this.equipment.logs.find(x => x.isActive);
      //         if (activeLog) {
      //             this.activeLog = activeLog;
      //         }
      //     });
  }

  ngOnInit(): void {
  }

  reportFailure() {
    const dialogRef = this.dialog.open(ReportFailureComponent, {
      width: "80%",
      maxWidth: "90%",
      disableClose: true,
      data: {
        title: "Fejlmeld udstyr",
        noButton: "Ananuler",
        yesButton: "Fejlmeld",
        message: "Vil du fejlmelde denne enhed?"
      }
    })

  dialogRef.afterClosed()
      .subscribe((dialogResult: boolean | string) => {
          if (dialogResult !== false) {
            this.equipmentService.createFailure(this.equipment.id!, {
                equipmentId: this.equipment.id!.toString(),
                comments: dialogResult as string,
                failureDate: new Date()
              })
              .subscribe(x => {
                  this.equipment.failed = true;
                  this.equipment.repairs!.push(x);
              });
          } 
      });
  }

  repairFinished(r: EquipmentRepairHistory) {
      r.repairFinishedDate = new Date();

      this.equipmentService.updateFailure(this.equipment.id!, r)
        .subscribe(x => {
          this.equipment.failed = false;

          //Find and update repair
          var repair = this.equipment.repairs?.find(y => y.id === x.id);
          if (repair) {
              Object.assign(repair, x);
          }
        });
  }
}
