import { CommonModule } from '@angular/common';
import { AfterContentInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ILoginRequest, ILoginResponse } from '../../interfaces/authentications/ilogin';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { filter } from 'rxjs';
import { environment } from '../../../environments/environment';

import packageJson from '../../../../package.json';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  implements OnInit, AfterContentInit {
  model: any = {};
  loading = false;
  error = '';
  isProduction = environment.production;
  appVersion: string = "";

  constructor(
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private authenticationService: AuthenticationService) { }
      
      ngOnInit() {
        // reset login status
        this.authenticationService.logout(this.authenticationService.refreshToken);

        this.appVersion = (packageJson as { version: string }).version;
      }

      ngAfterContentInit(): void {
        this.cdRef.detectChanges();
      }

  login() {
    const loginRequest: ILoginRequest = {
      username: this.model.userName,
      password: this.model.password,
      organizationId: '6' // Always Bovskav since there is no options for another company on the login form
    };
    
    this.loading = true;
    this.authenticationService.login(loginRequest)
      .pipe(filter((r: ILoginResponse | null) => r != null))
      .subscribe((result: ILoginResponse | null) => {
        // console.log("login result: ", result);
        if (result?.success === true) {
            // login successful
            if (this.authenticationService.previousUrl) {
                this.router.navigateByUrl(this.authenticationService.previousUrl);
            }
            else {
                this.router.navigate(['/']);
            }
        } else {
            // login failed
            this.error = 'Forkert brugernavn eller kodeord';
            this.loading = false;
        }
      });
  }
}
