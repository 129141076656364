import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subject, takeUntil, filter, map } from 'rxjs';
import { slideUpDownList } from '../../../animations/animations';
import { DamageCase } from '../../../models/damage-case';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { DamageCaseService } from '../../../services/damage-case.service';
import { UserService } from '../../../services/user.service';
import { NotificationService } from '../../../services/notification.service';
import { EventNotification } from '../../../interfaces/event-notification';
import { DamageCaseNote } from '../../../models/damage-case-note';
import { UserAvatarComponent } from "../../user-avatar/user-avatar.component";
import { AutosizeDirective } from '../../../directives/autosize.directive';
import { NewLinePipe } from '../../../pipes/new-line.pipe';

@Component({
  selector: 'app-case-note-list',
  standalone: true,
  imports: [CommonModule, FormsModule, UserAvatarComponent, AutosizeDirective, NewLinePipe],
  templateUrl: './case-note-list.component.html',
  styleUrls: ['./case-note-list.component.scss'],
  animations: [slideUpDownList]
})
export class CaseNoteListComponent implements OnInit, OnDestroy {
  @Input() case!: DamageCase;
  newNoteText = '';

  private destroy$ = new Subject<void>();

  constructor(
    public authService: AuthenticationService,
    private damageCaseService: DamageCaseService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.notificationService.notifications.pipe(
      takeUntil(this.destroy$),
      map(n => n as EventNotification<any, DamageCase>),
      filter(n => n.Context && n.Context.id === this.case.id)
    ).subscribe(n => {
      //console.log("notifications: ", n.Type);

      if (n.Type === "noteCreated") {
        const note = n.Data as DamageCaseNote;
        note.state = "add";
        this.case.notes.push(note);
        this.onUpdated();
      }
      if (n.Type === "noteUpdated") {
        const newNote = n.Data as DamageCaseNote;
        const note = this.case.notes.find(x => x.id === newNote.id);
        if (note) {
          Object.assign(note, newNote);
          this.onUpdated();
        }
      }
      if (n.Type === "noteDeleted") {
        const noteId = n.Data as number;
        const noteIndex = this.case.notes.findIndex(x => x.id === noteId);
        if (noteIndex !== -1) {
          this.case.notes[noteIndex].state = "remove";
          this.case.notes[noteIndex].editing = false;
          this.case.notes.splice(noteIndex, 1);
          this.case.hasNotes = this.case.notes.length > 0;
          this.onUpdated();
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onKey(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === "Enter") {
      this.saveNote();
    }
  }

  saveNote() {
    if (!this.newNoteText?.trim()) {
      return;
    }

    const note = new DamageCaseNote();
    note.note = this.newNoteText;
    note.damageCaseId = this.case.id;
    note.created = new Date();

    this.damageCaseService.addNote(note)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (n: any) => {
          n.state = "add";
          //console.log("adding note: ", n.result);
          this.case.notes.push(n.result)
          // Additional logic can be added here if needed
        },
        error: (error) => {
          console.error('Error adding note:', error);
          // Handle error (e.g., show user feedback)
        }
    });

    this.newNoteText = '';
  }

  noteSelected(note: DamageCaseNote): void {
    if (this.case.damageStatusId !== 3) {
      note.editing = true;

      this.cdRef.detectChanges();
    }
  }

  updateNote(event: Event, note: DamageCaseNote): void {
    event.stopPropagation();
    note.editing = false;
    note.updated = new Date();

    this.damageCaseService.updateNote(note).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => {
        // Handle successful update
      },
      error: (error) => {
        console.error('Error updating note:', error);
        // Handle error (e.g., show user feedback)
      }
    });
  }

  deleteNote(event: Event, note: DamageCaseNote): void {
    event.stopPropagation();
    note.state = "remove";
    note.editing = false;
    this.damageCaseService.deleteNote(note).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (r: any) => {
        // Additional logic can be added here if needed
        const noteIndex = this.case.notes.findIndex(x => x.id === note.id);
        if (noteIndex !== -1) {
          this.case.notes[noteIndex].state = "remove";
          this.case.notes[noteIndex].editing = false;
          this.case.notes.splice(noteIndex, 1);
          this.case.hasNotes = this.case.notes.length > 0;
          this.onUpdated();
        }
      },
      error: (error) => {
        console.error('Error deleting note:', error);
        // Handle error (e.g., show user feedback, revert UI changes)
      }
    });
  }

  readNote(event: Event, note: DamageCaseNote): void {
    event.stopPropagation();
    note.readById = this.authService.userId;
    note.readOn = new Date();
    this.damageCaseService.updateNote(note)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: () => {
          // Handle successful update
          this.onUpdated();
        },
        error: (error) => {
          console.error('Error marking note as read:', error);
          // Handle error (e.g., show user feedback)
        }
      });
  }

  private onUpdated(): void {
    // Implement any logic needed when notes are updated
    this.cdRef.detectChanges();
  }
}
