import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DamageCaseIdService {
  private selectedDamageCaseIdSubject = new BehaviorSubject<number | null>(null);
  selectedDamageCaseId$ = this.selectedDamageCaseIdSubject.asObservable();
  
  constructor() {
  }

   setSelectedDamageCaseId(value: number | null): void {
    this.selectedDamageCaseIdSubject.next(value);
   }
}
