import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthenticationService } from './services/auth/authentication.service';
import { AlertComponent } from "./components/alert/alert.component";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { HeaderComponent } from "./components/header/header.component";

import moment from 'moment';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [RouterOutlet, AlertComponent, CommonModule, FormsModule, HeaderComponent, MatSnackBarModule]
})
export class AppComponent implements OnInit {
  title = 'DamageReporter';
  signalRconnected: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
    public authService: AuthenticationService,
    private router: Router) {
    moment.locale("da");

    //Automatically check if the token is still valid
    setInterval(() => {
      if (this.authService.loggedin && !this.authService.isTokenValid()) {
       this.authService.logout(this.authService.refreshToken);

       this.authService.previousUrl = this.router.routerState.snapshot.url;
       this.router.navigate(['/login']);
      }
    }, 10000);
  }

  ngOnInit() {
    this.updateClient();
  }

  private updateClient() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .subscribe((evt: any) => {
          console.log("Event Type: " + evt.type);
          
          switch (evt.type) {
            case 'VERSION_DETECTED':
              console.log(`Downloading new app version: ${evt.version.hash}`);
              break;
            case 'VERSION_READY':
              console.log(`Current app version: ${evt.currentVersion.hash}`);
              console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
              this.snackBar.open("Der er en opdateret version tilgængelig", "Genindlæs nu", { duration: 30000 })
                .onAction()
                .subscribe(() => {
                  this.swUpdate.activateUpdate().then(() => document.location.reload());
                });
              break;
            case 'VERSION_INSTALLATION_FAILED':
              console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
              break;
            default:
              break;
          }
        });
    }
  }
}
