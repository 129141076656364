import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { Address } from '../../../models/address';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ComponentBaseComponent } from '../../component-base/component-base.component';
import { GoogleMap, GoogleMapsModule, MapDirectionsService } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { GoogleGeocodingService } from '../../../services/google-geocoding.service';

declare var google: any;

@Component({
  selector: 'driving-directions',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule],
  templateUrl: './driving-directions.component.html',
  styleUrl: './driving-directions.component.scss'
})
export class DrivingDirectionsComponent extends ComponentBaseComponent implements OnInit, OnChanges {
  @Input() case!: DamageCase;
  @Input() public baseAddress!: Address
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;

  center: google.maps.LatLngLiteral = { lat: 55.110850, lng: 14.704192 }; 
  coordinates: { lat: number; lng: number } | null = null;
  directionsResults: google.maps.DirectionsResult | null = null;
  distanceText: string = "";
  zoom = 10;

  distance!: string;

  constructor(auth: AuthenticationService,
    private geocodingService: GoogleGeocodingService,
    private mapDirectionsService: MapDirectionsService) {
    super(auth);
  }

  fetchCoordinates(address: string) {
    this.geocodingService.getCoordinates(address)
      .subscribe((response: any) => {
        if (response.status === 'OK') {
          const location = response.results[0].geometry.location;
          this.coordinates = { lat: location.lat, lng: location.lng };
          //this.distance = `${this.geocodingService.calculateDistance(this.center.lat, this.center.lng, this.coordinates!.lat, this.coordinates!.lng).toFixed(1)} km`;

          this.mapDirectionsService
          .route({
            origin: this.center,
            destination: this.coordinates,
            travelMode: google.maps.TravelMode.DRIVING,
          })
          .subscribe({
            next: (result) => {
              this.directionsResults = result.result as google.maps.DirectionsResult
              this.distanceText = this.directionsResults.routes[0].legs[0].distance?.text ?? '';
            },
            error: (error) => console.error('Error loading directions:', error),
          });
          
        } else {
          console.error('Geocoding error:', response.status);
          this.distance = "ukendt";
          this.directionsResults = null;
        }
      });
  }

  override ngOnInit() {
    this.fetchCoordinates(this.getCaseAddress());
  }


  ngOnChanges(changes: SimpleChanges) {
      this.fetchCoordinates(this.getCaseAddress());     
  }

  private getCaseAddress(): string {
    if (this.case.workAddress?.street && this.case.workAddress?.zip && this.case.workAddress?.city) {
      return `${this.case.workAddress.street}, ${this.case.workAddress.zip} ${this.case.workAddress.city}`;
    }
    return `${this.case.damageAddress.street}, ${this.case.damageAddress.zip} ${this.case.damageAddress.city}`;
  }
}
