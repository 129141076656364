import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SafetyDocument } from '../../../models/safety-document';
import { UploadComponent } from '../../upload/upload.component';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { SafetyDocsService } from '../../../services/safety-docs.service';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../ui/spinner/spinner.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FileUploadedArgsComponent } from '../../file-uploaded-args/file-uploaded-args.component';

@Component({
  selector: 'app-safty-docs',
  standalone: true,
  imports: [CommonModule, FormsModule, SpinnerComponent, UploadComponent, MatDialogModule, MatButtonModule],
  templateUrl: './safty-docs.component.html',
  styleUrl: './safty-docs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaftyDocsComponent implements OnInit {
  allSafetyDocs!: SafetyDocument[];
  allSafetyDocsView!: SafetyDocument[];

  @ViewChild(UploadComponent)
  public upload!: UploadComponent;

  uploadUrl: string = "/api/safetydocs/upload";
  loading: boolean = true;
  searchterm = "";

  constructor(
      public dialogRef: MatDialogRef<SaftyDocsComponent>,
      public authService: AuthenticationService,
      private cdRef: ChangeDetectorRef,
      private safetyDocsService: SafetyDocsService
  ) {
  }

  ngOnInit(): void {
    this.safetyDocsService.getSafetyDocs()
      .subscribe(result => {
        this.allSafetyDocs = result;
        this.allSafetyDocsView = result;
        this.loading = false;

        this.cdRef.detectChanges();
    });
  }

  close() {
    this.dialogRef.close();
  }  

  onUpload(event: MouseEvent): void {
    this.upload.upload();
  }


  onFileUploaded(event: FileUploadedArgsComponent): void {
    const value: SafetyDocument = event.getValue<SafetyDocument>();
    if (value) {
        if (!this.allSafetyDocs) {
            this.allSafetyDocs = [];
        }
        this.allSafetyDocs.push(value);
    }
  }

  downloadFile(fileName: string, openInNewWindow: boolean = true): void {
    this.safetyDocsService.downloadFile(fileName)
      .subscribe((blob) => {
     // Create a URL for the file
     const url = window.URL.createObjectURL(blob);

     if (openInNewWindow) {
       // Open file in a new browser window
       window.open(url);
     } else {
       // Download the file
       const a = document.createElement('a');
       a.href = url;
       a.download = fileName;
       document.body.appendChild(a);
       a.click();

       // Cleanup
       document.body.removeChild(a);
     }

     // Cleanup the object URL
     window.URL.revokeObjectURL(url);
      });
  }

  filterDocs(): void {
    // If search term is empty, reset to the full document list
    if (!this.searchterm) {
      this.allSafetyDocsView = this.allSafetyDocs;
    } 

    // Otherwise, filter documents by matching the fileName
    else {
      this.allSafetyDocsView = this.allSafetyDocs.filter(item =>
        item.fileName.toLowerCase().includes(this.searchterm.toLowerCase())
      );
    }
  }
}
