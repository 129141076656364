import { Component, OnInit } from '@angular/core';
import { Vehicle } from '../../../interfaces/vehicles/vehicle';
import { Employee } from '../../../models/employee';
import { VehicleService } from '../../../services/vehicles/vehicle.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../ui/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-vehicle-details',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './vehicle-details.component.html',
  styleUrl: './vehicle-details.component.scss'
})
export class VehicleDetailsComponent implements OnInit {
  public vehicle!: Vehicle;
  vehicleId: number | null = null;
  public employees!: Employee[];
  public selectedEmployee: Employee | null = null;
  selectedEmployeeVehicle: Vehicle | null = null;

  constructor(
      private vehicleService: VehicleService,
      public dialog: MatDialog,
      private authService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute,
      private userService: UserService,
  ) {
    const vehicleIdParam: string | null = this.route.snapshot.paramMap.get('vehiclesid');
    this.vehicleId = vehicleIdParam ? Number(vehicleIdParam) : null;

    if (this.vehicleId !== null) {
      this.vehicleService.getDetails(this.vehicleId)
        .pipe(filter(result => result != null))
        .subscribe((vehicleDetails: Vehicle) => {
          this.vehicle = vehicleDetails;
        });
    }    
  }

  ngOnInit(): void {
    this.userService.getEmployees()
      .pipe(filter(result => result != null))
      .subscribe((result: Employee[]) => {
        this.employees = result;
      });
  }

  addEmployee() {
    if (this.vehicle.employees?.findIndex(x => x.id == this.selectedEmployee?.id) === 0) {
      //console.log("Fundet");
    }

    if (this.vehicle.employees?.findIndex(x => x.id == this.selectedEmployee?.id) === -1) {
      if (this.selectedEmployee?.vehicleId == null) {
        this.vehicleService.addEmployee(this.vehicle.id!, this.selectedEmployee!)
          .subscribe(x => {
            this.vehicle.employees?.push(this.selectedEmployee!);
          });
      }
    } else {
      this.vehicleService.getDetails(this.selectedEmployee?.vehicleId!)
        .subscribe((v: Vehicle) => {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: "80%",
            maxWidth: "650px",
            data: {
              title: "Bil",
              yesButton: "Tilknyt i stedet til denne bil",
              noButton: "Bevar tilknytning til " + v.shortName,
              message: "Denne medarbejder er allerede tilknyttet en anden bil (" + this.selectedEmployee?.fullName +", " + v.longName + " - " + v.registrationNumber + ")."
            }
          })

          dialogRef.afterClosed()
            .subscribe((dialogResult: boolean) => {
                if (dialogResult == true) {
                  if (this.vehicle.employees?.findIndex(x => x.id == this.selectedEmployee?.id) === -1) {
                    this.vehicleService.addEmployee(this.vehicle.id!, this.selectedEmployee!)
                      .pipe(filter(result => result != null))
                      .subscribe((x: Vehicle) => {
                        this.vehicle.employees.push(this.selectedEmployee!);
                      });
                  }
                }
            });
      });
    }
  }

  removeEmployee(e: Employee) {
      this.vehicleService.removeEmployee(this.vehicle.id!, e)
        .subscribe(x => {
          const ix: number = this.vehicle.employees!.indexOf(e);
          this.vehicle.employees!.splice(ix, 1);
      })
  }

  // existingVehicle(vehicleId: number | null): void {
  //   if (vehicleId == null) {
  //     this.selectedEmployeeVehicle = null;
  //   } else {
  //     this.vehicleService.getDetails(vehicleId)
  //       .subscribe((vehicle: any) => this.selectedEmployeeVehicle = vehicle.result)
  //   }
  // }
}
