import { Component, OnInit } from '@angular/core';
import { Employee } from '../../../models/employee';
import { Vehicle } from '../../../interfaces/vehicles/vehicle';
import { Observable, switchMap } from 'rxjs';
import { EmployeesService } from '../../../services/employees/employees.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VehicleService } from '../../../services/vehicles/vehicle.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-employee-details',
  standalone: true,
  imports: [CommonModule, FormsModule, MatCheckboxModule],
  templateUrl: './employee-details.component.html',
  styleUrl: './employee-details.component.scss'
})
export class EmployeeDetailsComponent  implements OnInit {
  public employee!: Employee;
  public vehicle!: Vehicle;

  allVehicles: Observable<Vehicle[]>;
  selectedVehicle: Vehicle | null = null;

  constructor(private employeesService: EmployeesService,
      public authService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute,
      private vehicleService: VehicleService) {
      this.allVehicles = this.vehicleService.getVehicles();
  }


  ngOnInit(): void {
    this.route.params.pipe(
      switchMap((params: Params) => {
        return this.employeesService.getEmployee(params["employeeid"]);
      })
    ).subscribe((e: Employee) => {
      this.employee = e;
      this.allVehicles
        .subscribe((v: Vehicle[]) => {
          this.selectedVehicle = v.find(x => x.id == this.employee.vehicleId) || null;
        });  
    });
  }

  addVehicle() {
      if (this.employee.vehicle == null) {
        this.employee.vehicleId = this.selectedVehicle!.id as number;
        this.employee.vehicle = this.selectedVehicle;

        this.employeesService.updateEmployee(this.employee)
          .subscribe((e: Employee) => {
            //console.log("Add: ", e);
          });
      }

  }

  removeVehicle(event: Event, employee: Employee) {
      event.stopPropagation();
      employee.vehicleId = null;
      employee.vehicle = null;
      this.employeesService.updateEmployee(employee)
        .subscribe((e: Employee) => {
          this.selectedVehicle = null;
        });
  }


  onSubmitUpdateEmployee() {
    this.employeesService.updateEmployee(this.employee)
        .subscribe(dc => {
          this.employee.vehicleId = this.selectedVehicle!.id as number;
          this.employee.vehicle = this.selectedVehicle;
        });
  }
}
