import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { RegisterService } from '../services/register.service';

function usernameValidatorFactory(registerService: RegisterService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      let val: string = control.value;

      return registerService.isUsernameAvailable(val)
        .pipe(
            map(isAvailable => (isAvailable ? null : { validateUsername: true }))
        );
  }
}

@Directive({
  selector: '[validateUsername][ngModel]',
  standalone: true,
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => UserNameValidatorDirective), multi: true }]
})
export class UserNameValidatorDirective implements Validator {
  constructor(private registerService: RegisterService){
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return usernameValidatorFactory(this.registerService)(control) as Observable<ValidationErrors | null>;
  }

  validateMail(mail: string): Observable<any> {
    return new Observable(observer => {
        this.registerService.isUsernameAvailable(mail).subscribe(r => {
            if (r) {
                observer.next(null);
            }
            else {
                observer.next({ "validateUsername": true });
            }
        })
    });
  }
}
