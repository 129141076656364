import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, catchError, of, retry, tap } from 'rxjs';
import { ServiceBase } from '../base/service-base.service';
import { CompanyService } from '../company.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ILoginRequest, ILoginResponse } from '../../interfaces/authentications/ilogin';
import { CookieBaseService } from '../base/cookie-base.service';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { getEnumUserRoleIndexByValue } from '../../helpers/user-role-helper';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends ServiceBase {
  private baseurl = "api/oauth";
  private accountUrl = "api/account"

  public token: string | null = null;
  public refreshToken: string | null = null;
  public companyName!: string;
  public userLoginName!: string;
  public userFullName!: string;
  public userRole!: string;
  public userId!: string;
  public loggedin!: boolean;
  public companyId!: number;
  public isSuperAdmin!: boolean;
  public isAdmin!: boolean;
  public isEmployee!: boolean;
  public isCustomer!: boolean;
  public onLoggedIn: EventEmitter<string>;
  public previousUrl!: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    httpClient: HttpClient, 
    private notificationService: NotificationService,
    private jwtHelperService: JwtHelperService,
    private cookieBaseService: CookieBaseService,
    private companyService: CompanyService) {
      super(httpClient);
      this.onLoggedIn = new EventEmitter<string>();

      // set token if saved in local storage
      this.token = localStorage.getItem('currentUserToken');
      this.refreshToken = localStorage.getItem('currentRefreshToken');
      this.decodeToken();
      this.setOrUpdateCookie();      
  }

  public login(data: ILoginRequest): Observable<ILoginResponse | null> {  
    return this.http.post<ILoginResponse>(`${this.baseurl}/token`, data)
      .pipe(
        retry(2),
        tap((response: ILoginResponse) => {
          // Handle token
          this.token = response.access_token;       
          this.refreshToken = response.refreshToken;       
          localStorage.setItem('currentUserToken', response.access_token);
          localStorage.setItem('currentRefreshToken', response.refreshToken);

          this.decodeToken();
          this.setOrUpdateCookie();
          
          this.onLoggedIn.emit(this.userId);
          return true;
        }),
        catchError((err: any) => {
          console.error("err: ", err)
          const loginResponse: ILoginResponse = {
            access_token: '',
            refreshToken: '',
            success: false,
            token_type: '',
            expires_in: 0
          }
            
          if (isPlatformBrowser(this.platformId)) {
            const userName: string | null = localStorage.getItem('user_name');
            localStorage.clear();
            localStorage.setItem('user_name', userName ?? '');
          }

          return of(loginResponse);
        })
      )
  }
  
  logout(refreshToken: string | null): void {
    if (refreshToken) {
      // Perform the logout HTTP request
      this.postJson(`${this.baseurl}/logout`, { refreshToken: refreshToken })
        .subscribe({
          next: () => {
            this.clearTokens();
            this.notificationService.closeSignalRConnection();
          },
          error: (err) => {
            console.error('Logout failed', err);
            this.clearTokens();
            this.notificationService.closeSignalRConnection();
          },
        });
    }
  }

  refreshAccessToken(): Observable<any> {
    if (this.refreshToken) {
      return this.postJson<ILoginResponse>(`${this.baseurl}/refreshtoken`, { refreshToken: this.refreshToken })
        .pipe(
          tap((response: ILoginResponse) => {
            this.token = response.access_token;
            this.refreshToken = response.refreshToken;

            if (this.token || this.refreshToken) {
              localStorage.setItem('currentUserToken', response.access_token);
              localStorage.setItem('currentRefreshToken', response.refreshToken);

              this.decodeToken();
              this.setOrUpdateCookie();
            } else {
              this.logout(this.refreshToken);
            }
          })
      );
    } else {
      this.logout(this.refreshToken);
      return of(null);
    }
  }

  public isTokenValid(): boolean {
    if (this.token) {
      const timeleft: Date = this.jwtHelperService.getTokenExpirationDate(this.token) as Date;
      const diffInMilliseconds: number = Math.abs(new Date().getTime() - timeleft.getTime());
      const minutsLeft: number = Math.floor(diffInMilliseconds / (1000 * 60));
      // console.log("timeLeft: ", minutsLeft, this.token, this.refreshToken);

      if (minutsLeft === 0) {
        this.refreshAccessToken()
          .subscribe(() => {
            return !this.jwtHelperService.isTokenExpired(this.token);
          });
      }
    }

    return !this.jwtHelperService.isTokenExpired(this.token);
  }

  /**
   * Request a password reset for the given e-mail. Returns true regardless of whether the e-mail was found or not to prevent abuse
   * @param email
   */
  requestPasswordReset(email: string): Observable<boolean> {
      return this.postJson(`${this.accountUrl}/requestpasswordreset`, { email: email });
  }

  resetPassword(userId: string, newPassword: string, token: string) {
      return this.postJson(`${this.accountUrl}/resetpassword`, { userId: userId, newPassword: newPassword, token: token });
  }
 
  private decodeToken(): void {
    if (this.token) {
        const decoded = this.jwtHelperService.decodeToken(this.token);
        this.companyName = decoded['company'];
        this.userLoginName = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        this.userFullName = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
        this.userRole = getEnumUserRoleIndexByValue(decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']).toString();
        this.userId = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

        //Reset all to false first, just in case this wasn't  done elsewhere. We really don't want this to persist across logins
        this.isSuperAdmin = false;
        this.isAdmin = false;
        this.isEmployee = false;
        this.isCustomer = false;

        switch (this.userRole) {
            case '1':
                this.isSuperAdmin = true;
                this.isAdmin = true;
                break;
            case '2':
                this.isAdmin = true;
                break;
            case '3':
                this.isCustomer = true;
                break;
            case '4':
                this.isEmployee = true;
                break;
        }

        this.companyId = decoded.companyid;
        this.loggedin = !this.jwtHelperService.isTokenExpired(this.token);
        
        if (!this.loggedin) {
            this.logout(this.refreshToken);
        }
      }
      else {
        this.logout(this.refreshToken);
      }
  }

  private setOrUpdateCookie() {
    if (this.token) {
      let expireDays: number = 0;
      //Get the expiration date for the token and set a cookie, so we authenticate image requests as well
      //In the future this might use ServiceWorker or similar, so we don't need to rely on cookies
      //   let jwtHelper: JwtHelper = new JwtHelper();

      const expDate: Date | null = this.jwtHelperService.getTokenExpirationDate(this.token);
      if (expDate !== null) {
          expireDays = this.cookieBaseService.getDaysDifference(new Date(), expDate);
      }

      this.cookieBaseService.setCookie({name:'DRAuth', value:this.token, expireDays:expireDays });
    }

    if (this.refreshToken) {
      // Refresh token does not contain an expire date 
      // But the API is hardcoded to use 30 days.

      let expireDays: number = 0;

      // Get today's date
      const now = new Date();

      // Add 30 days
      const expDate = new Date();
      expDate.setDate(now.getDate() + 30);

      if (expDate !== null) {
          expireDays = this.cookieBaseService.getDaysDifference(new Date(), expDate);
      }

      this.cookieBaseService.setCookie({name:'DRRefreshToken', value:this.token, expireDays:expireDays });
    }
  }

  private clearTokens() {
    // clear token remove user from local storage to log user out
    this.token = null;
    this.refreshToken = null;
    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('currentRefreshToken');
    
    // Delete cookies
    this.cookieBaseService.deleteCookie("DRAuth");
    this.cookieBaseService.deleteCookie("DRRefreshToken");

    this.loggedin = false;
    this.companyName = "";
    this.userLoginName = "";
    this.userFullName = "";
    this.userRole = "";
    this.userId = "";
    this.companyId = 0;

    //Reset all the type flags
    this.isSuperAdmin = false;
    this.isAdmin = false;
    this.isEmployee = false;
    this.isCustomer = false;

    this.companyService.reset();
  }
}
